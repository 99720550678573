.active-dialog /deep/ .el-dialog__body {
  padding: 50px 55px 45px 50px !important;

  p {
    font-size: 15px;
    color: $dark-gray;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .btn-box {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 45% 45%;
    column-gap: 10%;
  }
}

@media (max-width: 767px) {
  .active-dialog /deep/ .el-dialog__body {
    padding: 20px !important;

    .btn-box {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      row-gap: 20px;
    }
  }
}
