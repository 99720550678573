/deep/ .el-dialog__header {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url(~@/assets/images/dialog/header.png);

  .el-dialog__headerbtn {
    display: none;
  }
}

/deep/.el-dialog__footer {
  padding: 0;

  .chat {
    font-size: 15px;
    color: $dark-gray;
    text-transform: uppercase;
    line-height: 1.2;

    img,
    div {
      display: inline-block;
    }

    img {
      @include rtl-sass-prop(margin-right, margin-left, 10px);
      margin-bottom: 5px;
    }
  }

  .background {
    height: 20px;
    border-radius: 0 0 8px 8px;
    background-color: $blue;
  }
}

.dialog-body {
  @include rtl-sass-value(padding, 0 35px 45px 30px, 0 30px 45px 35px);
}

.el-dialog__footer {
  .chat {
    @include rtl-sass-value(text-align, left, right);
    @include rtl-sass-value(padding, 0 55px 45px 50px, 0 50px 45px 55px);
  }

  img {
    @include rtl-sass-prop(margin-right, margin-left, 10px);
  }
}

@media (max-width: 767px) {
  /deep/ {
    .el-dialog__footer .chat {
      padding: 0 20px 20px;
    }
  }

  .dialog-body {
    @include rtl-sass-value(padding, 0 35px 45px 30px, 0 30px 45px 35px);
  }
}
